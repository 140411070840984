.course-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  
}
.reg-main-title {
  color: var(--primary-color);
  font-family: var(--font-primary);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  margin-bottom: 60px;
}
.title-decor {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.line {
  height: 2px;
  width: 50px;
  background-color:var(--dark_green);
  margin: 0 5px;
}

.decor-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 50px;
}

.title-content {
  max-width: 600px;
  padding: 20px;
}

.reg-heading {
  color:var( --secondary-color); 
}

.reg-subtitle {
  font-size:var( --font-size-small);
  color:var( --dark_green);
  margin-bottom: 20px;
  font-family: var( --font-secondary);
}

.reg-main-title {
font-size: var(--font-size-medium);
color: var(--primary-color);
font-family: var(  --font-primary);
font-weight: var(  --font-weight-bold);
margin-bottom: 60px;
text-align: center;
}

.reg-form-group {
  margin-bottom: 15px;
}
.reg-form-group label{
  color: var( --secondary-color);
  font-size: 12px;
  font-weight: 500;
}

.reg-form-control {
  width: 100%;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  background-color: #F6F6F6;
}
.reg-form-control::placeholder{
  color: #adb5bd ;
  font-size: 14px;
}
.button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.reg-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size:var( --font-size-small);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
 
.reg-review-list {
  list-style: none;
  padding: 0;
  
}
 .reg-review-list strong{
  color:var( --secondary-color)  !important;
 }
.reg-img-fluid {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  object-fit: cover;
  display: block;
}

.form-container {
  background-color:var(--white);
  padding: 30px;
  border-radius: 0 10px 10px 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.no-gap-row {
  margin-right: 0;
  margin-left: 0;
}

.no-gap-row > .col-md-6 {
  padding-right: 0;
  padding-left: 0;
}
.title-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;  
}
@media (max-width: 768px) {
  .line, .decor-icon {
    display: none;
  }
  .reg-heading {
    width: 130%;
    font-size: 1.75rem; 
    padding: 0 1rem;  
    white-space: normal;  
  }
  .reg-heading {
    font-size: 1.75rem; 
    padding: 0 1rem;  
  }
  .reg-subtitle {
    font-size: 1rem;
  }

  .title-content {
    margin-top: 20px;
  }

  .reg-img-fluid, .form-container {
    border-radius: 10px;
  }

  .row.no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .col-md-6 {
    padding-right: 0;
    padding-left: 0;
  }
}

.success-message {
  text-align: center;
  padding: 20px;
  border: 1px solid #24a77e;
  background-color: #e6ffed;
  border-radius: 5px;
  margin-top: 20px;
}

.success-message h2 {
  color: #24a77e;
}

.success-message p {
  color: #2e4a49;
}



.reg-review-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  background-color: #f9f9f9;  
  border-radius: 8px;  
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  
  overflow: hidden;
}

.reg-review-list li {
  padding: 15px 20px;
  border-bottom: 1px solid #eee; 
  font-size:var( --font-size-small);  
  display: flex;
  justify-content: space-between;  
  align-items: center;
}

.reg-review-list li:last-child {
  border-bottom: none;  
}

.reg-review-list li strong {
  color: #333; 
  font-weight:var( --font-weight-bold);  
}

.reg-review-list li span {
  color: #555;  
}
.error-message{
  color: red;
}



@media (max-width: 768px) {
  .reg-review-list li {
    flex-direction: column; 
    align-items: flex-start;
  }
  .reg-review-list li strong, .reg-review-list li span {
    margin-bottom: 5px; 
  }
  
}

 



 
/* Optional: Style the dropdown container for a cleaner look */
.reg-form-control {
  margin-bottom: 10px;
}
