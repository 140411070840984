/* About.css */
@import '../base/variable.css'; /* Ensure you're importing the variables */

.about-section {
    background-color: var(--background-color);
    padding: var(--padding-section) 0;
    position: relative;
}
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;  
    width: 100%;
  }
.about-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    align-items: center;
}
.about-text {
    text-align: left;
}
.about_btn{
    display: flex;
    gap: 10px;
}
.about-heading {
    text-align: center;
    font-size: var(--font-size-large);
    margin-bottom: 40px;
    color: var(--primary-color); 
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing);
    font-weight: var(--font-weight-bold); 
    border-bottom: 2px solid var(--primary-color);
    display: inline-block; 
    padding-bottom: 10px;
    position: relative;
    font-family: var(--font-primary);
}
.about-title {
    font-family: var(--font-primary);
    font-size: var(--font-size-large);
    color: var(--secondary-color);
    font-weight: var(--font-weight-bold);
    margin-bottom: 20px;
}

.about-subtitle {
    font-family: var(--font-secondary);
    font-size: var(--font-size-medium);
    color: var(--secondary-color); 
    margin-bottom: 20px;
    font-style: italic;
}

.about-description {
    font-size:var( --font-size-paragraph);
    color: var(--text-color);
    line-height: 1.8;
    margin-bottom: 30px;
}
.about-images {
    position: relative;
    left: 34px;
  
}
.main-image {
    border-radius: 10px;
    width: 80%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.secondary-image {
    border-radius: 10px;
    width: 80%;
    height: auto;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    bottom: -25px;
    right: 40px;
}

@media (max-width: 768px) {
    .about-content {
        grid-template-columns: 1fr;
    }
    .about-images {
        position: relative;
        left: 0;
    }   
.main-image {
    border-radius: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}
.secondary-image {
    border-radius: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    bottom: -40px;
    right: -20px;
}
.about_btn{
    display: flex;
    font-size: 12px;
    gap: 10px;
}
}
