@import '../base/variable.css';

.parallax-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    text-align: left;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
}
.parallax-content {
    z-index: 1;
    width: 100%; 
}

.parallax-container .list {
    position: absolute;
    top: 40px;
    right: 20px;
    list-style: none;
    display: flex;
    gap: 20px;
    font-size: 20px;
    margin-right: 190px;
    font-weight: 500;
}
.parallax-container .list li {
    color: var(--white);
    cursor: pointer;
}
.parallax-content h1 {
    font-size: 3rem;
    color: var(--white);
    width: 100%;
    font-weight: var(--font-weight-bolder);
    letter-spacing: var(--letter-spacing);
    text-shadow: var(--switch-box-shadow);
    margin-bottom: 20px;  
    transition: transform 0.3s ease, opacity 0.3s ease;
}
.parallax-content p {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-medium);
    color: var(--light-text-color);
    max-width: 500px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}
.parallax-content .btn {
    font-size: var(--font-size-paragraph);
    font-weight: var(--font-weight-bold);
    color: var(--white);
    background-color: var(--primary-color);
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}
.parallax-content .btn:hover {
    background-color: var(--primary-color-dark);
    transform: scale(1.05);
}
.transition-wrapper {
    margin-top: 80px;
}
@media (max-width: 768px) {
    .parallax-container {
        align-items: center;
        text-align: center;
        padding-left: 0;
  
    }
    .parallax-content h1 {
        font-size: 3rem;
        font-weight: var(--font-weight-bolder);
        color: var(--white);
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    }
    .parallax-content p {
        font-size: 1.2rem;
        font-weight: var(--font-weight-normal);
        color: var(--light-text-color);
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
    }
    .parallax-content .btn {
        font-size: var(--font-size-small);
    }
    .parallax-container .list {
        flex-direction: column;
        gap: 10px;
        top: 10px;
        right: 10px;
        margin-right: 0;
    }
}
.parallax-desktop {
    display: none;
}
@media (min-width: 769px) {
    .parallax-mobile {
        display: none;
    }
    .parallax-desktop {
        display: block;
    }
}
@media (max-width: 768px) {
    .parallax-mobile {
        display: block;
    }
}
