/* Container styling */
.contact-container {
  padding: 50px 0;
}
/* Wrapper styling */
.contact-wrap {
  display: flex;
  flex-wrap: wrap;
}
.contact-wrap {
  margin-top: -8rem;
  margin-bottom: 3.125rem;
  background-color: var(--pm-white);
  position: relative;
  z-index: 1; 
}
.contact-content, .contact-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bg-light {
  padding: 40px 30px;
  border-radius: 10px;
  border: 1px solid rgb(221, 221, 221);
  background-color: var(--white) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
/* Contact content styling */
.contact-content {
  background:var(--white);
  padding: 30px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact-content h2{
color:var(--secondary-color);
font-size: var(--font-size-large);
text-align: start;
}

.para {
  font-size: var( --font-size-small);
  line-height: 1.6;
  color:var( --secondary-color);
  text-align:start;
}

/* Contact info styling */
.contact-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.contact-info-icon {
  background-color: var(--primary-color);
  color:var(--white);
  border-radius: 50%;
  padding: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.contact-info-content {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.contact-info-label {
  font-weight: 500;
  color:var(--primary-color);
  font-size: 14px;
}
.contact-info-dec {
  color:var( --secondary-color);
  font-size: 14px;
  font-weight:var(  --font-weight-bold );
  line-height: normal;
}

/* Form control styling */
.form-control {
  border: none;
  background-color: #F6F6F6;
  border-radius: 30px;
}
.form-control::placeholder {
color:var( --placeholder-color);
  font-size: 14px;
}
input:focus,
textarea:focus {
  outline: none;
  border-color: var(--focus-color);

}
/* Textarea styling */
textarea.form-control {
  resize: none;
  border-radius: 12px;
}
/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .contact-content {
      margin-bottom: 30px;
  }
  .contact-title {
      font-size: 1.5rem;
  }
}
