@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Merriweather:wght@400;700&display=swap');

:root {
    /* Color palette */
    --primary-color: #89b9c3;  
    --secondary-color: #0C3C47;  
    --background-color: #F0F8F5;  
    --text-color: #35373a; /* Main text color */
    --hover-color: white; /* Hover state color for buttons and links */
    --light-text-color: #d8e8e7; /* Lighter text for descriptions and subtitles */
    --Green-color:#24a77e;
    --white: #ffffff;
    --dark_green:#2E4A49;
    --switch_background_color:rgba(255, 255, 255, 0.9);
    --placeholder-color:#a3bcbf;
    --focus-border-color: #89b9c3; /* Primary green accent for focus */
    --placeholder-color: #a3bcbf; /* Light teal gray placeholder color */
    --focus-placeholder-color: #0C3C47;
    --Event_color:#2e604b;

    /* Typography */
    --font-primary: 'Montserrat', sans-serif; /* Primary font for body text */
    --font-secondary: 'Merriweather', serif; /* Secondary font for headings */
    --font-size-large: 2.5rem; /* Large font size for major headings */
    --font-size-medium: 1.2rem; /* Medium font size for content */
    --font-size-small: 1rem; /* Small font size for less prominent items */
    --font-size-paragraph: 1.1rem; /* Font size for paragraphs */

    --font-weight-normal: 400; /* Normal font weight */
    --font-weight-bold: 600; /* Bold font weight */
    --font-weight-bolder: 700; /* Bold font weight */

    /* Spacing */
    --padding-section: 70px; /* Padding for sections */
    --margin-section: 40px; /* Margin for section separation */
    --letter-spacing: 1.5px; /* Letter spacing */
    --button-padding: 15px 42px; /* Padding for buttons */
    /* shadow box  */
    --switch-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

ol, ul , li  ,a{
    padding-left: 0rem;
cursor: pointer;
}

* ,a{
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}
 
/* styles.css or whichever global stylesheet you use */
body {
    background-color: var(--background-color); /* Set the default background color for the body */
   }  
  
  /* Specific override for the Home page */
  .body-home {
  
    background-color: transparent; /* Optional: make it transparent or set a specific color for the home */
  }
  
  .section-spacing{
padding:50px;
  }
  dl, ol, ul {
    margin-top: 0;
    margin-bottom: 0rem;
  }
  body::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
input:focus, textarea:focus {
  outline: none;
}