@import '../base/variable.css';

/* Button Styles */
.button {
  background-color: var(--primary-color);
  color: var(--white);
  padding: var(--button-padding);
  border: none;
  border-radius: 30px;
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
 
}
.button:hover {
  background-color: var(--white);
  transform: translateY(-3px);
  color: var(--primary-color);
}

.button .btn-title, .button-outline .btn-title, .button-black-text .btn-title {
  margin-right: 10px; /* Space before the arrow */
}

.button .arrow, .button-outline .arrow, .button-black-text .arrow {
  font-size: 1em;
  transition: margin-left 0.3s;
  color: var(--white); /* Initial color */
}

.button:hover .arrow, .button-outline:hover .arrow, .button-black-text:hover .arrow {
  margin-left: 5px; /* Movement on hover */
  color: var(--primary-color); /* Color change on hover */
}

.button .arrow:before, .button-outline .arrow:before, .button-black-text .arrow:before {
  content: '➔'; /* Unicode arrow symbol */
  display: inline-block; /* Ensures the content is displayed */
}

/* New button style */
.button-outline {
  background-color: transparent;
  color: var(--white);
  padding: var(--button-padding);
  border-radius: 30px;
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
  border: 2px solid var(--white);
}

.button-outline:hover {
  background-color: var(--white);
  color: var(--dark_green);
}

/* Black text button style */
.button-black-text {
  background-color: transparent;
  color: var(--primary-color);
  padding: var(--button-padding);
  border: none;
  border-radius: 30px;
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
  border: 1px solid var(--primary-color);
}

.button-black-text:hover {
  background-color: var(--primary-color);
  transform: translateY(-3px);
  color: var(--white);
}

.button-black-text .btn-title {
  margin-right: 10px; /* Space before the arrow */
}

.button-black-text .arrow {
  color: var(--primary-color);
  margin-left: 10px; /* Initial position */
}

.button-black-text:hover .arrow {
  color: var(--white);
  margin-left: 5px; /* Move arrow closer on hover */
}

.button-black-text .arrow:before {
  content: '➔'; /* Unicode arrow symbol */
  display: inline-block;
  transform: rotate(180deg); /* Rotate to point left */
}



.button + .button, 
.button + .button-outline, 
.button + .button-black-text,
.button-outline + .button,
.button-outline + .button-outline,
.button-outline + .button-black-text,
.button-black-text + .button,
.button-black-text + .button-outline,
.button-black-text + .button-black-text {
  margin-left: 10px; /* Add desired space between buttons */
}
