
.Service_section{
	position:relative;
	padding: 0px 0px;
}
.Leaf_Icon{
  color: white;
  font-size: 27px;
  margin-left: -15px;
}
.Service_section .upper-box{
	position: relative;
	padding: 100px 0px 0px;
	background:var( --secondary-color) ;
}

.Service_section .upper-box .pattern-layer{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url('../../assets/img/service/services-background.jpg') center top repeat;
	opacity: 0.05;
}

.tabs-box{
	position: relative;
}

.tabs-box .buttons{
	position: relative;
	display: block;
}

.tabs-box .tab-buttons{
	position: relative;
}

.tabs-box .tab-buttons .tab-btn{
	position: relative;
}

.tabs-box .tabs-content{
	position: relative;
}

.tabs-box .tabs-content .tab{
	position: relative;
	display: none;
}

.tabs-box .tabs-content .active-tab{
	display: block;
}

.service-tabs .buttons{
	position: relative;
	display: block;
	margin-bottom: -60px;
 
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 4px;
	z-index: 5;
}

.service-tabs .tab-buttons{
	margin: 0 0;
}

.service-tabs .tab-buttons .tab-btn{
	position: relative;
	padding: 30px 20px;
	text-align: center;
	background: #F0F8F5;
	border-right: 1px dashed rgba(0,0,0,0.10);
	color:#0C3C47;
	cursor: pointer;
	-webkit-transition:background 0.3s ease;
	-moz-transition:background 0.3s ease;
	-ms-transition:background 0.3s ease;
	-o-transition:background 0.3s ease;
	transition:background 0.3s ease;
}

.service-tabs .tab-buttons .tab-btn:last-child{
	border-right: none;
}

.service-tabs .tab-buttons .active-btn{
	background: #132728;
	color: #ffffff;
}

.service-tabs .tab-buttons .tab-btn .hvr-seeds{
	position: absolute;
	right: 0;
	top: 0;
	width: 70px;
	height: 70px;
	/* background: url('./ ') right top no-repeat; */
	opacity: 0;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.service-tabs .tab-buttons .active-btn .hvr-seeds{
	opacity: 1;
}

.service-tabs .tab-buttons .tab-btn .icon-box{
	position: relative;
	display: block;
	font-size: 64px;
	line-height: 1em;
}

.service-tabs .tab-buttons .tab-btn .icon-box .icon{
	position: relative;
	display: block;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.sec-title{
	position:relative;
	padding-left: 80px;
	min-height: 65px;
	margin-bottom:55px;
}

.sec-title.alternate{
	padding-left: 0px;
	padding-right: 80px;
	margin-bottom:0px;
	text-align: right;
}

.sec-title:before{
	content: '';
	position: absolute;
	left: 0px;
	top: 3px;
	width: 40px;
	height: 65px;
	background:var( --primary-color);
}

.sec-title.alternate:before{
	left: auto;
	right: 0;
}

.sec-title .title-icon{
	position: absolute;
	left: 20px;
	top: 32px;
}

.sec-title.alternate .title-icon{
	left: auto;
	right: 20px;
}

.sec-title .subtitle{
	position: relative;
	display: block;
	line-height: 1.6em;
	font-size: 14px;
	color:var( --primary-color);
	font-weight: 700;
	text-transform: uppercase;
	margin: 0 0 5px;
}

.sec-title h2{
	position: relative;
	display: block;
	font-size:40px;
	line-height: 1.30em;
	color:white;
	font-weight:var(--font-weight-bolder);
    font-family: var( --font-primary);
	margin-bottom: 0px;
}

h4{
    font-family: var(--font-primary);
    color: var( --dark_green);
    /* display: flex; */
    
}
.sec-title h2 strong{
	font-weight: var( --font-weight-bolder);
}

.sec-title .sub-text{
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 1.6em;
	font-weight: 400;
	color: #585f5f;
	padding: 10px 0px 0px;
	margin-left: -80px;
}

.service-tabs .tab-buttons .active-btn .icon-box .icon{
	opacity: 0;
	color: #24a77e;

}
*{
  text-decoration: none;
  list-style: none;
}
.service-tabs .tab-buttons .tab-btn .icon-box .hvr-icon{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	opacity: 0;
}

.service-tabs .tab-buttons .active-btn .icon-box .hvr-icon{
	opacity: 1;
}

.service-tabs .tab-buttons .tab-btn .btn-title{
	position: relative;
	display: block;
	font-size: var(--font-size-small);
	font-weight:var(--font-weight-bolder);
	text-transform: capitalize;
	font-family:var( --font-secondary);
	margin-top: 25px;
  
}

.service-tabs .tab-buttons .tab-btn .arrow{
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	line-height: 38px;
	margin: 20px auto 0px;
	border: 1px dashed rgba(0,0,0,0.15);
	color: rgba(0,0,0,0.15);
	border-radius: 50%;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.service-tabs .tab-buttons .active-btn .arrow{
	border-color:var(--primary-color);
	color:var(--primary-color);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

 


 

.service-tabs .tabs-content{
	position: relative;
}

.service-tabs .tabs-content .tab{
	position: relative;
}

.service-tabs .tabs-content .outer-container{
	position: relative;
	margin: 0 0;
}

.service-tabs .tabs-content .image-column{
	position: relative;
	padding: 120px 0px 100px 0px;
}

.service-tabs .tabs-content .image-column .inner{
	position: relative;
	display: block;
}

.service-tabs .tabs-content .image-column .content{
	position: relative;
	display: block;
	float: right;
	width: 100%;
	max-width: 340px;
}

.service-tabs .tabs-content .image-layer{
	position:absolute;
	left:0;
	top:0;
	right: 170px;
	bottom: 0;
	height:100%;
	opacity:1;
	z-index: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}
.image-column.fixed-height {
	position: relative;
	height: 650px; /* Set the fixed height for the image container */
	overflow: hidden;
  }
  
  .text-column {
	overflow: auto;
	max-height: 700px; /* Ensure the text column does not exceed a certain height */
  }
  
  
.service-tabs .tabs-content .image-column .inner-box{
	position: relative;
	display: block;
	text-align: center;
	padding: 35px 50px;
	background:var( --primary-color);
	color:var(--white);
}

.service-tabs .tabs-content .image-column .icon-box{
	position: relative;
	display: block;
	line-height: 1em;
	font-size: 54px;
	margin-bottom: 20px;
}

.service-tabs .tabs-content .image-column h5{
	font-size: 24px;
	font-weight: 700;
	text-transform: capitalize;
	line-height: 1.4em;
	margin-bottom: 15px;
}

.service-tabs .tabs-content .image-column .text{
	font-size: 16px;
	line-height: 1.6em;
	margin-bottom: 25px;
  font-family: 'Lato', sans-serif;
  color: #d8e8e7; /* Dark Green */
}

.service-tabs .tabs-content .text-column{
	position: relative;
	 
	padding: 120px 0px 50px 0px;
}

.service-tabs .text-column .big-icon{
	position: absolute;
	right: 30px;
	top: 70px;
	font-size: 550px;
	line-height: 1em;
	color: #f3faf8;
}

.service-tabs .tabs-content .text-column .inner{
	position: relative;
	display: block;
	padding-left: 60px;
	padding-right: 15px;
	max-width: 600px;
}

.service-tabs .tabs-content .text-column .content{
	position: relative;
	display: block;
}

.service-tabs .tabs-content .s-title{
	position: relative;
 
	min-height: 60px;
	margin-bottom: 30px;
}

.service-tabs .tabs-content .s-title .icon{
	position: absolute;
	left: 0;
	top: 2px;
	font-size: 64px;
	line-height: 1em;
	color:var(--primary-color);
}

.service-tabs .tabs-content .s-title .subtitle{
	position: relative;
	display: block;
	font-size: 14px;
	color:var(--primary-color);
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 7px;
}

.service-tabs .tabs-content .s-title h4{
	position: relative;
	font-size: 30px;
	text-transform: capitalize;
	font-weight: 700;
	margin-bottom: 0;
}

.service-tabs .tabs-content .text{
	position: relative;
	margin-bottom: 30px;
  
}

.service-tabs .tabs-content .b-box{
	position: relative;
	max-width: 380px;
	padding: 16px 20px 15px 144px;
	min-height: 88px;
	background: #ffffff;
	box-shadow: 1px 10px 15px 0px rgba(0,0,0,0.07);
}

.service-tabs .tabs-content .b-box .image{
	position: absolute;
	left: 0;
	top: 0;
	width: 124px;
	height: 88px;
	box-shadow: 3px 0px 10px 0px rgba(0,0,0,0.07);
}

.service-tabs .tabs-content .b-box .image img{
	width: 100%;
	height: 88px;
}

.service-tabs .tabs-content .bro-title{
	position: relative;
	font-size: 18px;
	text-transform: capitalize;
	font-weight: 600;
	margin-bottom: 3px;
}

.service-tabs .tabs-content .bro-link{
	position: relative;
	display: block;
}

.service-tabs .tabs-content .bro-link .theme-btn{
	position: relative;
	display: inline-block;
	vertical-align: top;
	color: #24a77e;
	font-weight: 500;
	border-bottom: 1px dashed;
}

.service-tabs .tabs-content .bro-link .theme-btn i{
	position: relative;
	font-size: 11px;
}

.service-tabs .tabs-content .more-link{
	position: relative;
	display: block;
	margin-top: 40px;
}

.service-tabs .tabs-content .more-link .theme-btn{
	position: relative;
	display: inline-block;
	vertical-align: top;
  background-color:#24a77e;
	color: white;
	font-weight: 700;
	font-size: 15px;
	text-transform: uppercase;
}

.service-tabs .tabs-content .more-link .theme-btn i{
	position: relative;
	font-size: 11px;
	padding-left: 5px;
}

.service-tabs .tabs-content .more-link .theme-btn:hover{
	color: #24a77e;
}

/***

====================================================================
	Why Us Section
====================================================================

***/

.why-us{
	position:relative;
	padding: 100px 0px 70px;
}

.why-us .pattern-layer{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url('../../assets/img/gallery/countryside.jpg') center top repeat;
}

.why-us .right-leaf{
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url('../../assets/img/gallery/green-leaves.jpg') right center no-repeat;
}

.why-us .title-box{
	position: relative;
	margin-bottom: 50px;
}

.why-us .title-box .text{
	position: relative;
	padding-top: 10px;
	line-height: 1.7em;
	max-width: 490px;
}

.why-block{
	position: relative;
	margin-bottom: 30px;
}

.why-block .inner-box{
	position: relative;
	display: block;
	text-align: center;
	padding: 45px 25px 20px;
	background: #ffffff;
	border-radius: 7px;
	min-height: 100%;
	box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10);
}

.why-block .icon-box{
	position: relative;
	top: 0;
	display: block;
	width: 90px;
	height: 90px;
	margin: 0 auto 30px;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.why-block:hover .icon-box{
	top: -5px;
}

.why-block .icon-box span{
	position: relative;
	display: block;
	width: 90px;
	height: 90px;
	background: #ffffff;
	color: #24a77e;
	font-size: 48px;
	line-height: 90px;
	border-radius: 50%;
	box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.07);
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.why-block:hover .icon-box span{
	color: #ffffff;
	background: #24a77e;
}

.why-block .icon-box:before{
	content: '';
	position: absolute;
	display: block;
	left: -8px;
	top: 0;
	width: 90px;
	height: 90px;
	background: #eaeaea;
	border-radius: 50%;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.why-block:hover .icon-box:before{
	left: 8px;
	background: #228a69;
}

.why-block h5{
	position: relative;
	top: 0;
	color: #132728;
	font-size: 20px;
	font-weight: 700;
	text-transform: capitalize;
	margin-bottom: 12px;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.why-block:hover h5{
	opacity: 0;
}
 
.why-block .text{
	position: relative;
	top: 0;
  
	margin-bottom: 0px;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}
.text{
display: flex;
    font-size: var( --font-size-paragraph);
    font-family: var(--text-color);
    color: var(--text-color);
    align-items: start;
}

.why-block:hover .text{
	top: -45px;
}

.why-block .more-link{
	position: relative;
	top: 0;
	display: block;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.why-block:hover .more-link{
	top: -20px;
}

.why-block .more-link a{
	position: relative;
	display: inline-block;
	width: 60px;
	height: 60px;
	line-height: 60px;
	color: #777777;
	font-size: 20px;
}

.why-block .more-link a:before{
	content: '';
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	background:#24a77e url('../../assets/img/gallery/sunset-through-window.jpg') 65% 65% no-repeat;
	opacity: 0;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.why-block:hover .more-link a:before{
	opacity: 1;
}

.why-block .more-link a span{
	position: relative;
	z-index: 1;
}

.why-block:hover .more-link a{
	color: #ffffff;
}

.why-block .right-curve{
	position: absolute;
	right: 15px;
	bottom: 15px;
	border: 24px solid transparent;
	border-right: 24px solid #f3faf8;
	border-bottom: 24px solid #f3faf8;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.why-block:hover .right-curve{
	opacity: 0;
}

.why-block .right-curve:before{
	content: '';
	position: absolute;
	right: -15px;
	bottom: -15px;
	width: 48px;
	height: 48px;
	background: url('../../assets/img/gallery/green-leaves.jpg') right bottom no-repeat;
}
 

/* Adjust .sec-title to be centered */
.sec-title {
	display: flex;
	flex-direction: column; /* Ensure children stack vertically */
	justify-content: center; /* Center children vertically */
	align-items: center; /* Center children horizontally */
	text-align: center; /* Center text */
  }
  
  /* Additional adjustments to ensure proper spacing and alignment */
  .sec-title h2 {
	position: relative;
	display: block;
	font-size: 40px;
	line-height: 1.30em;
	color: white;
	font-weight: var(--font-weight-bolder);
	font-family: var(--font-primary);
	margin-bottom: 0px;
  }

.service-tabs .tabs-content .text-column .inner {
	padding-left: 20px;  /* Adjust the padding to align the text nicely */
	padding-right: 20px; /* Adjust the padding to align the text nicely */
  }
  
  .service-tabs .tabs-content .text-column .content .text {
	text-align: left;    /* Ensure text starts from the left */
	margin-bottom: 10px; /* Space between text sections */
  }
  
  .service-tabs .tabs-content .more-link {
	display: flex;
	justify-content: flex-start;
  }
  
  .service-tabs .tabs-content .text-column .content .button-outline {
	margin-top: 20px;    /* Space above the button */
  }
  

  /* Text Styling */

.text {
    font-size: 16px; /* Base font size */
    color: #333; /* Darker color for text */
    margin-bottom: 20px; /* Space below each paragraph */
    font-family: 'Arial', sans-serif; /* Font family for text */
}

.text strong {
    font-weight: 700; /* Bold for strong elements */
}

.text ul {
    padding-left: 20px; /* Indentation for list items */
    list-style-type: disc; /* Disc style for list items */
}

.text ul li {
    margin-bottom: 10px; /* Space below each list item */
}


@media (max-width: 768px) {
	.service-tabs .tabs-content .image-layer{
		position:absolute;
		left:0;
		top:0;
		right: 135px;
		bottom: 0;
		height:81%;
		opacity:1;
		z-index: 0;
		 
	}
	.service-tabs .tabs-content .text-column{
	 
		padding: 0px 0px 50px 0px;
	}
	

}