@import'../base/variable.css';
/* switcher  */
/* Add this CSS file to the ParallaxComponent */
.language-switcher {
	display: flex;
	gap: 0.5rem;
	margin-left: auto;
	position: fixed;
	top: 100px;
	right: 0px;
	z-index: 1000;
	background:var(--switch_background_color);
	padding: 0.5rem 1rem;
	border-radius: 20px;
	box-shadow:var(--switch-box-shadow);
  }
  .language-button {
	padding: 0.5rem;
	font-size: 1.5rem;
	color: #333;
	background: none;
	border: none;
	cursor: pointer;
	transition: all 0.3s;
  }
  .language-button.active {
	color:var(--secondary-color);
	transform: scale(1.1);
  }
  .language-button:hover {
	color:var( --primary-color);
	transform: scale(1.05);
  }
  .parallax-container {
	transition: opacity 0.3s;
  }
  
  