 
.navbar {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  height: 80px; /* Fixed height for the Navbar */
   padding: 0 0.5rem;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1030;
  transition: background-color 0.3s ease-out, backdrop-filter 0.3s ease-out;
  display: flex;  
  align-items: center;  
}
.navbar.transparent {
  background: #2e4a4965;
  backdrop-filter: blur(6px);
}
.navbar-brand {
  display: flex;
  align-items: center;
}
.logo {
  height: 70px; /* Adjust the height of the logo as needed */
  width: auto;
  transition: height 0.3s;
}
/* Language switcher styles */
.language-switcher {
  display: flex;
  gap: 0.5rem;
}
.language-button {
  padding: 0.5rem;
  font-size: 1.5rem;
  color: var(--white);
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
}
.language-button.active {
  color: var(--hover-color);
  transform: scale(1.1);
}
.language-button:hover {
  color: var(--hover-color);
  transform: scale(1.05);
}
/* Hamburger menu styles */
.hamburger-menu {
  display: none;
  cursor: pointer;
  z-index: 2000;
}
.hamburger-menu .bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
}
.hamburger-menu.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Navbar menu styles */
.nav-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the right */
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  overflow: hidden;
  max-height: none;  
  opacity: 1;  
  transform: scaleY(1);  
}

.nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-left: 1rem;
}

.nav-link {
  font-family: var(--font-secondary);
  font-weight: var(--font-weight-bold);
  color: var(--white) !important;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s, border-bottom 0.3s;
  border-bottom: 2px solid transparent;
}

.nav-link:hover {
  color: var(--hover-color) !important;
  border-bottom: 2px solid var(--hover-color); /* Underline on hover */
}

/* Remove underline for mobile menu */
@media (max-width: 800px) {
  .hamburger-menu {
    display: block;
  }

  .nav-menu {
    display: none;  
    flex-direction: column;
    align-items: center; 
    justify-content: center; /* Center the items vertically */
    width: 100%;
    height: 100vh;  
    position: fixed;  
    top: 0;  
    left: 0;  
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    padding: 1rem;
    z-index: 1050; /* Ensure it is above other content */
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    transform: scaleY(0); /* Initially scale the menu */
  }

  .nav-menu.open {
    display: flex;  
    max-height: 100vh;  
    opacity: 1;
    transform: scaleY(1);  
  }

  .nav-list {
    width: 100%;
    flex-direction: column;
  }

  .nav-item {
    width: 100%;
    text-align: center;
    margin: 0.5rem 0;
  }

  .nav-link {
    width: 100%;
    border-bottom: none !important; 
  }
 
}
