
.calendar-container, .event-list-container {
  padding: 0;  
  display: flex;  
  flex-direction: column;  
}
.text {
  color:var( --dark_green);
  font-size:var(--font-size-small);
  margin-left: 0px;
}
.event-tabs {
  margin-left: 70px;
  font-size:var(--font-size-small);
}
.calendar {
  background:var(--white);
  color: #333;
  padding: 30px;  
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  flex: 1;  
  display: flex;
  flex-direction: column;
}
.event-list-container {
  background-color:var(--Event_color);  
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 27px;  
  flex: 1;  
  height: auto;  
}
.event-list {
  background:var(--white);
  color: #333;
  padding: 15px;
  width: 90%;
  margin-left: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;  
}
.text_local {
  color: var(--white);
  margin-left: 30px;
  font-weight: var( --font-weight-bold);
}
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.calendar-header h2 {
  font-size: 1.5rem;
  color: #91AD7A;
}
.calendar-controls button {
  background: none;
  border: none;
  font-size:var(--font-size-medium);
  cursor: pointer;
}
.calendar-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;  
}
.day-names {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  color:var(--secondary-color);
  font-size: 15px;
}
.dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  flex-grow: 1;  
}
.date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;  
  border-radius: 5px;  
  transition: background 0.3s;
  position: relative;
  cursor: pointer;
  color: rgb(32, 32, 32);
  font-size: 14px;
  font-weight: 500;
}
.calendar-date {
  color: #8a8a8a;
}
.date h3 {
  font-size: 14px;
}
.date p {
  font-size: 13px;
  color:var(--white);
}
.date.today {
  background:var(--Green-color);
  color:var(--white);
}
.date .event-details {
  display: none;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: #228a69;
  color:var(--white);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 10;
}
.date:hover .event-details {
  display: block;
}
.event-tabs {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  margin-left: 30px;
  gap: 20px;
}
.event-tabs button {
  background: none;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  cursor: pointer;
   
  transition: border-color 0.3s;
}
.event-tabs button:hover,
.event-tabs button.active {
  border-color:var(--white);
}
.events {
  display: flex;
  flex-direction: column;
  gap: 10px; 
  flex-grow: 1;  
}
.event-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  background: #f9f9f9;  
  border-radius: 5px;  
}
.event-item.passed {
  opacity: 0.6;  
}
.event-item .time {
  width: 60px;
  text-align: left;
  font-size: var( --font-size-small);
  font-weight: bold;
  font-weight: 500;
  color: rgb(0, 0, 0);
  position: relative;
}

.event-item .time::after {
  content: '';
  width: 2px;
  height: 80%;
  background:var(--Green-color);
  position: absolute;
  top: 50%;
  left: 113%;
  transform: translateY(-50%);
}
.event-item .type {
  display: block;
  font-size: 0.8rem;
  margin-top: 5px;
  font-weight: 500;
  color: #d6d6d6;
}
.event-item .details {
  flex-grow: 1;
  padding-left: 20px;
}
.event-item .details h3 {
  margin: 0;
  font-size:var(--font-size-small);
  font-weight: bold;
  font-family: sans-serif;
  color: #0b0b0b;
}
.event-item .details .location {
  font-size: 0.9rem;
  color: #777;
}
.event-item .additional-details {
  text-align: right;
}
.event-item .additional-details .price {
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
}
.price {
  margin-right: 13px;
  font-weight: 500;
  font-size: 15px;
  color: rgb(32, 32, 32);
}
.event-item .additional-details .date {
  font-size: 0.9rem;
  color: #777;
}
/* Media Queries for Responsive Adjustments */
@media (max-width: 768px) {
  .calendar, .event-list {
    margin-bottom: 20px;
  }
  .event-tabs {
    align-items: center;
  }
}
.loading {
  text-align: center;
  margin-top: 50px;
  font-size: 1.5rem;
  color:var( --dark_green);
}
/* Header Section Styles */
.header-title {
  font-family: 'Montserrat', sans-serif;  
  font-weight:var(--font-weight-bold); 
  color:var(--secondary-color); 
  margin: 5px 0;
}

.header-subtitle {
  font-family: 'Lato', sans-serif;  
  font-size:var(--font-size-medium); 
  font-weight:var(--font-weight-normal);  
  color:var(--dark_green);  
}
.calendar-controls {
  display: flex;
  align-items: center;
  justify-content: center;  
}
.calendar-controls button {
  background-color:var(  --Green-color);  
  border: none;
  color:var(--white); 
  text-decoration: none;
  display: inline-block;
  font-size:var(    --font-size-small);
  font-weight:var( --font-weight-bolder);
  margin: 4px;
  cursor: pointer;
  border-radius: 50%;  
  width: 40px; 
  height: 40px;  
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}
.calendar-controls button:hover {
  background-color:var(  --Green-color);
  opacity: 0.5;
}
.event-header {
  display: flex;
  align-items: center;
}
.details {
  display: flex;
  flex-direction: column;  
  align-items: center;  
  text-align: center;  
} 
.details h3 {
  margin: 0 0 10px 0;  
}
.details .location {
  margin-bottom: 10px;  
}
.date {
  text-align: center;  
  margin-top: 10px;  
}

@media (max-width: 768px) {
  .calendar, .event-list {
      margin-bottom: 0px;
  }
  .event-list-container{
padding: 13px;
  }
  .line_event_calender {
 display:none;
  }
  
}