.gallery-container {
  width: 100%;
  padding: 20px;
  background-color: var(--white);
  border-radius: 8px;
}

.space {
  padding: 70px;
  color: var(--white);
}

.gallery-header {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  padding-bottom: 20px;
}

.gallery-header h1 {
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  color: var(--secondary-color);
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}

.gallery-header h1::before,
.gallery-header h1::after {
  content: '';
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: var(--secondary-color);
  top: 50%;
  transform: translateY(-50%);
}

.gallery-header h1::before {
  left: -70px;
}

.gallery-header h1::after {
  right: -70px;
}

.gallery-header p.page-description {
  font-family: var(--font-secondary);
  font-size: 1.125rem;
  line-height: 1.6;
  color: var(--dark_green);
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 200px);
  gap: 10px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.gallery-item img:hover {
  transform: scale(1.05);
  filter: brightness(80%);
}

.gallery-item::before {
  content: attr(alt);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  font-size: var(--font-size-small);
  font-family: var(--font-primary);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover::before {
  opacity: 1;
}

/* Desktop layout */
.gallery-item:nth-child(1) {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

.gallery-item:nth-child(2) {
  grid-column: 3 / 5;
  grid-row: 1 / 2;
}

.gallery-item:nth-child(3) {
  grid-column: 1 / 2;
  grid-row: 2 / 4;
}

.gallery-item:nth-child(4) {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.gallery-item:nth-child(5) {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

.gallery-item:nth-child(6) {
  grid-column: 4 / 5;
  grid-row: 2 / 4;
}

.gallery-item:nth-child(7) {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

.gallery-item:nth-child(8) {
  grid-column: 3 / 4;
  grid-row: 3 / 4;
}

/* Mobile layout */
@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 200px);
    gap: 10px;
  }

  .gallery-item:nth-child(1) {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }

  .gallery-item:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .gallery-item:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .gallery-item:nth-child(4) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  .gallery-item:nth-child(5) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .gallery-item:nth-child(6) {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .gallery-item:nth-child(7) {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
  }

  .gallery-item:nth-child(8) {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
  }
}

/* Tablet layout */
@media (min-width: 769px) and (max-width: 1024px) {
  .gallery-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 200px);
  }

  .gallery-item:nth-child(1) {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  .gallery-item:nth-child(2) {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }

  .gallery-item:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .gallery-item:nth-child(4) {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
  }

  .gallery-item:nth-child(5) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .gallery-item:nth-child(6) {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .gallery-item:nth-child(7) {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
  }

  .gallery-item:nth-child(8) {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
  }
}
